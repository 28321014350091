import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const panoramagalerie = () => {
  return (
    <Layout>
      <SEO title="Samsung IFA 20xx Panorama" />
      <h1>Samsung Cebit 20xx Panorama</h1>

      <div
        style={{
          margin: "1rem",
        }}
      >
        <iframe
          src="/files/samsung/app-files/index.html"
          title="360 Panorama Ansicht"
          style={{
            width: "100%",
            minHeight: "400px",
            display: "flex",

            border: "none",
          }}
        ></iframe>
        <p style={{ textAlign: "center" }}>
          <AniLink
            cover
            to="/panorama/panorama-galerie"
            direction="right"
            bg="
          url(https://www.inkandfeather.de/ink-logo-white.webp)
          center / cover   /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          white            /* color */          
          "
          >
            zurück zur Panorama-Galerie
          </AniLink>
        </p>
      </div>
    </Layout>
  )
}

export default panoramagalerie
